import React, { useState } from "react";
import { object, number, array } from "prop-types";
import { Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import { SectionTag } from "@quintype/arrow";

import CardImage from "../../atoms/card-image";
import Photo from "../../basic/images/photo.svg";
import Video from "../../basic/images/video.svg";
import StarShowHide from "../../atoms/star-show-hide";
import TimeStamp from "../../atoms/time-stamp";

import "./card-image-type-9.m.css";
//Story Card with hidden star-icon and pääaihe word. Used in search
const CardImageType9 = ({ story, showSection = false, showSubHeadline = false, widths = [250, 375] }) => {
  const storyObj = story.story ? story.story : story;
  //console.log("section", storyObj)
  const paaaihe = storyObj.metadata?storyObj.metadata["pääaihe"]:undefined;

  const imgS3keys= storyObj["hero-image-s3-key"];
  const accessType = storyObj.access;
  const [isLogged, setIsLogged] = useState(false);
  const getIcon = type => {
    //console.log("type", type)
    if (type === "photo") {
      return (
        <div styleName="icon">
          <img src={`${assetify(Photo)}`} alt="photo" />
        </div>
      );
    } else if (type === "video") {
      return (
        <div styleName="icon">
          <img src={`${assetify(Video)}`} alt="video" />
        </div>
      );
    }
  };

  return (
    <Link href={`/${storyObj.slug}`}>
      <div styleName="wrapper" className="card-image-type-9">
        <div className="main-headline story-content-no-img" styleName={imgS3keys?"story-content":"story-content-no-img"}>
          <div styleName="wrapper-inside" className="wrapper-inside">
            {/*<HeroImage story={item.story} />*/}
            <h2 styleName="headline" className="headline-layout4 main-headline-layout1 layout4">
              {/*<Link href= {`/${storyObj.slug}`} styleName="span-newest">{paaaihe && `${paaaihe} | `}</Link>*/}
                <span styleName="span-newest">{paaaihe && `${paaaihe} | `}</span>
                {storyObj.headline}
              {/*<Link className="main-headline-layout1 layout4" href={`/${storyObj.slug}`}>{storyObj.headline}</Link>*/}
            </h2>
           {showSubHeadline && <h5 styleName="headline" className="subheadline">
              {" "}
              <Link className="subheadline" href={`/${storyObj.slug}`}>{storyObj.subheadline}</Link>
            </h5>}
            <div styleName="section-time-wrapper">
              {/*<div className="section-tag-layout1">
                <Link href= {`/${storyObj.slug}`} styleName="span-newest">{paaaihe && `${paaaihe} | `}</Link>
              </div>*/}
              <div className="time-stamp-layout1">
                <TimeStamp timeStamp={storyObj["published-at"]} />
              </div>
              {/*(accessType === "login" || accessType==="subscription") &&
               <div>
                  <i className="ri-star-fill"></i>
            </div>*/}
              <StarShowHide accessType={accessType}/>
            </div>
          </div>
        </div>
        <div styleName="card-image-type-9" className="card-image-type-9-card">
            {showSection&& <SectionCategoryTitle name={storyObj.sections[0].name} />}
            {imgS3keys !==null && <div>
              <div styleName="image-desktop">
                <CardImage
                  story={storyObj}
                  defaultWidth={183}
                  aspectRatio={[3, 2]}
                  imageType="image3x2"
                  widths={widths}
                />
              </div>
              <div styleName="image-mobile">
                <CardImage
                  story={storyObj}
                  defaultWidth={89}
                  aspectRatio={[1, 1]}
                  imageType="image1x1"
                  widths={widths}
                />
              </div>

          <div styleName="info" className="info">
            {getIcon(storyObj["story-template"])}
          </div>
          </div>}
        </div>
      </div>
    </Link>
  );
};

export default CardImageType9;

CardImageType9.propTypes = {
  story: object,
  headlineLimit: number,
  widths: array
};
