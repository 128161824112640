import React, { useState } from "react";
import { object, number, array } from "prop-types";
import { Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import { SectionTag } from "@quintype/arrow";

import StarShowHide from "../../atoms/star-show-hide";
import Photo from "../../basic/images/photo.svg";
import Video from "../../basic/images/video.svg";
import TimeStamp from "../../atoms/time-stamp";
import CardImage from "../../atoms/card-image";

import "./card-image-type-6.m.css";

const CardImageType6 = ({ story, showSection = false, showSubHeadline = false, widths = [250, 375] }) => {
  const storyObj = story.story ? story.story : story;
  //console.log("section", storyObj)
  const paaaihe = storyObj.metadata?storyObj.metadata["pääaihe"]:undefined;

  const imgS3keys= storyObj["hero-image-s3-key"];
  const accessType = storyObj.access;
  const [isLogged, setIsLogged] = useState(false);
  const getIcon = type => {
    //console.log("type", type)
    if (type === "photo") {
      return (
        <div styleName="icon">
          <img src={`${assetify(Photo)}`} alt="photo" />
        </div>
      );
    } else if (type === "video") {
      return (
        <div styleName="icon">
          <img src={`${assetify(Video)}`} alt="video" />
        </div>
      );
    }
  };

    /* getting url right regarding different papers*/

    function findByKey(key, value) {
      return (item, i) => item[key] === value
    }

    let findParams = findByKey("domain-slug", "suupohjan-sanomat") //change here
    let positionOfPaper = storyObj.sections.findIndex(findParams)

    var parts = storyObj.slug.lastIndexOf("/");
    var storySlug = storyObj.slug.substring(parts+1);

    // Check if there is section for that paper
    if (storyObj.sections[positionOfPaper]){
      var splitUrl = storyObj.sections[positionOfPaper]["section-url"].split("/"); //split when slash
      var joinUrl = splitUrl.slice(3); //slice after third slug
      var finalUrl = joinUrl.join("/"); //join the remaining parts with slash
      //console.log("final url " + finalUrl + "/" + storySlug);
    }

  return (
    <Link href={
      positionOfPaper !== -1 ? (
        storyObj.sections[positionOfPaper] ? (
          `/${finalUrl}/${storySlug}`
        ) : (
          `/${storyObj.sections[positionOfPaper].slug}/${storySlug}`
        )
      ) : (
        `/${storyObj.slug}`
      )
    }>
      <div styleName="wrapper" className="card-image-type-6">
        <div className="main-headline story-content-no-img" styleName={imgS3keys?"story-content":"story-content-no-img"}>
          <div styleName="wrapper-inside" className="wrapper-inside">
            {/*<HeroImage story={item.story} />*/}
            <h2 styleName="headline" className="headline-layout4 main-headline-layout1 layout4">
              <span styleName="span-newest">{paaaihe && `${paaaihe} | `}</span>
              {storyObj.headline}
              {/*<Link className="main-headline-layout1 layout4" href={`/${storyObj.slug}`}>{storyObj.headline}</Link>*/}
            </h2>
           {showSubHeadline && <h5 styleName="headline" className="subheadline">
              {" "}
              <Link className="subheadline" href={`/${storyObj.slug}`}>{storyObj.subheadline}</Link>
            </h5>}
            <div styleName="section-time-wrapper">
              {/*<div className="section-tag-layout1">
                <Link href= {`/${storyObj.slug}`} styleName="span-newest">{paaaihe && `${paaaihe} | `}</Link>
              </div>*/}
              <div className="time-stamp-layout1">
                <TimeStamp timeStamp={storyObj["published-at"]} />
              </div>
              <StarShowHide accessType={accessType}/>
            </div>
          </div>
        </div>
        <div styleName="card-image-type-6" className="card-image-type-6-card">
            {showSection&& <SectionCategoryTitle name={storyObj.sections[0].name} />}
            {imgS3keys !==null && <div>
          <CardImage
            story={storyObj}
            defaultWidth={259}
            aspectRatio={[16, 9]}
            imageType="image16x9"
            widths={widths}
          />
          <div styleName="info" className="info">
            {getIcon(storyObj["story-template"])}
          </div>
          </div>}
        </div>
      </div>
    </Link>
  );
};

export default CardImageType6;

CardImageType6.propTypes = {
  story: object,
  headlineLimit: number,
  widths: array
};
