import React, { useState } from "react";
import { object, number, array } from "prop-types";
import { Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import { SectionTag } from "@quintype/arrow";

import CardImage from "../../atoms/card-image";
import Photo from "../../basic/images/photo.svg";
import Video from "../../basic/images/video.svg";

import TimeStamp from "../../atoms/time-stamp";
import Star from "../../basic/images/star-fill.svg";
import StarShowHide from "../../atoms/star-show-hide";

import "./card-image-type-8.m.css";
import { SectionCategoryTitle } from "../section-category-title";

const CardImageType8 = ({ story, showSection = false, showSubHeadline = false, widths = [250, 375] }) => {
  const storyObj = story.story ? story.story : story;
  const paaaihe = storyObj.metadata?storyObj.metadata["pääaihe"]:undefined;

  var strippedTextBlock;
  if (storyObj && storyObj.cards) {
    if (storyObj.cards[0]["story-elements"]) {
        var firstTextBlock = storyObj.cards[0]["story-elements"][0].text||undefined;
        strippedTextBlock = firstTextBlock&&firstTextBlock.replace(/(<([^>]+)>)/gi, "");
        if (firstTextBlock === '' || firstTextBlock === undefined || firstTextBlock === null) strippedTextBlock === "";
        else strippedTextBlock = firstTextBlock.replace(/(<([^>]+)>)/gi, "");
      }
    }
    else (strippedTextBlock = '');

  //console.log("story", storyObj)
  const imgS3keys= storyObj["hero-image-s3-key"];
  const accessType = storyObj.access;
  const [isLogged, setIsLogged] = useState(false);
  const getIcon = type => {
    //console.log("type", type)
    if (type === "photo") {
      return (
        <div styleName="icon">
          <img src={`${assetify(Photo)}`} alt="photo" />
        </div>
      );
    } else if (type === "video") {
      return (
        <div styleName="icon">
          <img src={`${assetify(Video)}`} alt="video" />
        </div>
      );
    }
  };

    /* getting url right regarding different papers*/

    function findByKey(key, value) {
      return (item, i) => item[key] === value
    }

    let findParams = findByKey("domain-slug", "suupohjan-sanomat") //change here
    let positionOfPaper = storyObj.sections.findIndex(findParams)

    var parts = storyObj.slug.lastIndexOf("/");
    var storySlug = storyObj.slug.substring(parts+1);

    // Check if there is section for that paper
    if (storyObj.sections[positionOfPaper]){
      var splitUrl = storyObj.sections[positionOfPaper]["section-url"].split("/"); //split when slash
      var joinUrl = splitUrl.slice(3); //slice after third slug
      var finalUrl = joinUrl.join("/"); //join the remaining parts with slash
      //console.log("final url " + finalUrl + "/" + storySlug);
    }

  return (
    <Link href={
      positionOfPaper !== -1 ? (
        storyObj.sections[positionOfPaper] ? (
          `/${finalUrl}/${storySlug}`
        ) : (
          `/${storyObj.sections[positionOfPaper].slug}/${storySlug}`
        )
      ) : (
        `/${storyObj.slug}`
      )
    }>
      <div styleName="wrapper" className="card-image-type-8">
        <div styleName="card-image-type-8" className="card-image-type-8-card">
            {showSection&& <SectionCategoryTitle name={storyObj.sections[0].name} />}
            {imgS3keys !==null && <div>
              <div styleName="image-desktop">
                <CardImage
                  story={storyObj}
                  defaultWidth={183}
                  aspectRatio={[3, 2]}
                  imageType="image3x2"
                  widths={widths}
                />
              </div>
              <div styleName="image-mobile">
                <CardImage
                  story={storyObj}
                  defaultWidth={89}
                  aspectRatio={[1, 1]}
                  imageType="image1x1"
                  widths={widths}
                />
              </div>
          <div styleName="info" className="info">
            {getIcon(storyObj["story-template"])}
          </div>
          </div>}
        </div>
        <div className="main-headline" styleName={imgS3keys?"story-content":"story-content-no-img"}>
       { /*<div className="main-headline" styleName="story-content">*/}
          <div styleName="wrapper-inside" className="wrapper-inside">
            {/*<HeroImage story={item.story} />
            <h2 styleName="headline" className="headline-layout4">
            <Link className="main-headline-layout1 layout4" href={`/${storyObj.slug}`}><span styleName="span-newest">{paaaihe && `${paaaihe} | `}</span>{storyObj.headline}</Link>
            <Link className="main-headline-layout1 layout4" href={`/${storyObj.slug}`}><Link href= {`/${storyObj.sections[0].slug}`} styleName="span-newest">{`${storyObj.sections[0].name} | `}</Link>{storyObj.headline}</Link>*/}
            <h2 styleName="headline" className="headline-layout4 main-headline-layout1 layout4">
              <span styleName="span-newest">{paaaihe && `${paaaihe} | `}</span>
              {storyObj.headline}
            </h2>
           {/*showSubHeadline && <h5 styleName="headline" className="subheadline">
              {" "}
              <Link className="subheadline" href={`/${storyObj.slug}`}>{storyObj.subheadline}</Link>
            </h5>*/}
            {/*<h5 styleName="paragraph">{strippedTextBlock}</h5>*/}
            <div styleName="section-time-wrapper">
              {/*<div className="section-tag-layout1">
                <SectionTag story={storyObj} />
            </div>*/}
              <div className="time-stamp-layout1">
                <TimeStamp timeStamp={storyObj["published-at"]} />
              </div>
              <StarShowHide accessType={accessType}/>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardImageType8;

CardImageType8.propTypes = {
  story: object,
  headlineLimit: number,
  widths: array
};
