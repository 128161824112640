import React, { useState } from "react";
import { object, number, array } from "prop-types";
import { Link } from "@quintype/components";

import { SectionTag } from "@quintype/arrow";
import assetify from "@quintype/framework/assetify";

import CardImage from "../../atoms/card-image";
import TimeStamp from "../../atoms/time-stamp";
import Photo from "../../basic/images/photo.svg";
import Video from "../../basic/images/video.svg";
import Star from "../../basic/images/star-fill.svg";
import StarShowHide from "../../atoms/star-show-hide";
import { SectionCategoryTitle } from "../section-category-title";

import "./card-image-type-4.m.css";

const CardImageType4 = ({ story, showSection=false, showSubHeadline = false, widths = [250, 375] }) => {
  const storyObj = story.story ? story.story : story;
  const nativeAd = story.metadata["sponsored-by"];
  const paaaihe = storyObj.metadata?storyObj.metadata["pääaihe"]:undefined;
  const accessType = storyObj.access;
  const imgS3keys= storyObj["hero-image-s3-key"];
  const[isLogged, setIsLogged] = useState(false);
  const getIcon = type => {
    if (type === "photo") {
      return (
        <div styleName="icon">
           <img src={`${assetify(Photo)}`} alt="photo"/>
        </div>
      );
    } else if (type === "video") {
      return (
        <div styleName="icon">
         <img src={`${assetify(Video)}`} alt="video"/>
        </div>
      );
    }
  };

    /* getting url right regarding different papers*/

    function findByKey(key, value) {
      return (item, i) => item[key] === value
    }

    let findParams = findByKey("domain-slug", "suupohjan-sanomat") //change here
    let positionOfPaper = storyObj.sections.findIndex(findParams)

    var parts = storyObj.slug.lastIndexOf("/");
    var storySlug = storyObj.slug.substring(parts+1);

    // Check if there is section for that paper
    if (storyObj.sections[positionOfPaper]){
      var splitUrl = storyObj.sections[positionOfPaper]["section-url"].split("/"); //split when slash
      var joinUrl = splitUrl.slice(3); //slice after third slug
      var finalUrl = joinUrl.join("/"); //join the remaining parts with slash
      //console.log("final url " + finalUrl + "/" + storySlug);
    }

  return (
    <Link href={
      positionOfPaper !== -1 ? (
        storyObj.sections[positionOfPaper] ? (
          `/${finalUrl}/${storySlug}`
        ) : (
          `/${storyObj.sections[positionOfPaper].slug}/${storySlug}`
        )
      ) : (
        `/${storyObj.slug}`
      )
    }>
      <div styleName="wrapper" className="card-image-type-4">
            {showSection && !nativeAd &&<SectionCategoryTitle name={storyObj.sections[0].name} />}
            {/*showSection && !nativeAd && <div styleName="title-line" className="title-line">
                <hr styleName="hr-style" className="hr-style"/>
                <Link styleName="category-title" className="category-title">{storyObj.sections[0].name}</Link>
            </div>*/}
            {imgS3keys !==null && <div>
              <CardImage
              story={storyObj}
              defaultWidth={259}
              aspectRatio={[3, 2]}
              imageType="image3x2"
              widths={widths}
            />
            <div styleName="info" className="info">
            {getIcon(storyObj["story-template"])}
            </div>
        </div>}
      </div>
      {/*<div className="main-headline" styleName="story-content">*/}

      <div styleName="card-image-type-4-texts">
      <div className="main-headline" styleName={imgS3keys? "story-content":"no-img"}>
          <div styleName="wrapper-inside" className="wrapper-inside">
              {/*<HeroImage story={item.story} />*/}
              <h2 styleName="headline" className="main-headline-layout1 layout4">
                <span styleName="span-newest">{paaaihe && `${paaaihe} | `}</span>
                {storyObj.headline}
              {/*<Link className="main-headline-layout1 layout4" href={`/${storyObj.slug}`}><Link href= {`/${storyObj.sections[0].slug}`} styleName="span-newest">{`${storyObj.sections[0].name} | `}</Link>{storyObj.headline}</Link>*/}
              </h2>
              {showSubHeadline &&<h2 styleName="headline" className="subheadline">
                {" "}
                <Link className="subheadline" href={`/${storyObj.slug}`}>{storyObj.subheadline}</Link>
              </h2>}
            <div styleName="section-time-wrapper">
              {/*<div className="section-tag-layout1">
                  <SectionTag story={storyObj} />
              </div>*/}
              <div className="time-stamp-layout1">
                  <TimeStamp timeStamp={storyObj["published-at"]} />
              </div>
              <StarShowHide accessType={accessType}/>
            </div>
          </div>
          </div>
      </div>
      {/*<div className="story-content" styleName="story-content">
          <h3 styleName="main-headline" className="three-imgs">{showSection && <SectionTag story={storyObj} />}{storyObj.headline}</h3>
      </div>*/}
    </Link>
  );
};

export default CardImageType4;

CardImageType4.propTypes = {
  story: object,
  headlineLimit: number,
  widths: array
};
